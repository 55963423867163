import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { styles } from "../../utils"
import Dish from "../globals/Dish"

const DishShowcase = () => {
  const {
    chickenSandwich,
    freshSalad,
    pintsOnThePatio,
  } = useStaticQuery(graphql`
    query {
      chickenSandwich: contentfulAsset(
        title: { eq: "ABK - Hot Chicken Sandwhich" }
      ) {
        description
        gatsbyImageData(formats: WEBP, quality: 75, width: 750)
      }
      freshSalad: contentfulAsset(title: { eq: "ABK - Fresh Salads" }) {
        description
        gatsbyImageData(formats: WEBP, quality: 75, width: 750)
      }
      pintsOnThePatio: contentfulAsset(
        title: { eq: "ABK - Pints on the Patio" }
      ) {
        description
        gatsbyImageData(formats: WEBP, quality: 75, width: 750)
      }
    }
  `)

  const dishes = [
    {
      img: chickenSandwich,
      name: `Chicken Sandwich`,
      pos: `50% 60%`,
    },
    {
      img: freshSalad,
      name: `Fresh Salads`,
      pos: `50% 35%`,
      posMd: `50% 25%`,
    },
    {
      align: `right`,
      img: pintsOnThePatio,
      name: `Pints on the Patio`,
      pos: `50% 50%`,
    },
  ]

  return (
    <Wrapper>
      <Dish {...dishes[0]} />
      <ContentWrapper>
        <Content>
          <p>
            <Highlight>Azalea Bar & Kitchen</Highlight> evokes a sophisticated
            yet chilled vibe meant to inspire and awaken the senses. Enjoy a
            chef-driven menu that fuses classic fare with elevated techniques,
            local beverages, curated cocktails, and house-made desserts that
            celebrate the tastes of the South.
          </p>
        </Content>
      </ContentWrapper>
      <Dish {...dishes[1]} />
      <Dish {...dishes[2]} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  --gap: 1rem;
  --height: 200vh;
  --max-width: 100vmax;
  --temp: repeat(4, 1fr) / 1fr;

  display: grid;
  gap: var(--gap);
  grid-template: var(--temp);
  height: var(--height);
  margin: auto;
  max-width: var(--max-width);
  padding: var(--gap);

  @media (min-width: 768px) and (max-aspect-ratio: 1.4/1) {
    --gap: 1rem;
    --height: 100vh;
    --max-width: 100vh;
    --temp: 1fr 1fr / 1fr 1fr;
  }

  @media (min-aspect-ratio: 1.4/1) {
    --temp: 1fr 1fr / 1fr 1fr;
  }

  @media (min-height: 768px) {
    --height: 100vh;
    --max-width: 100vh;
  }
`

const ContentWrapper = styled.div`
  background: ${styles.colors.primaryColor};
  padding: var(--gap);
`

const Content = styled.article`
  border: 2px solid ${styles.colors.secondaryColor};
  color: ${styles.colors.mainWhite};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: var(--gap);
  height: 100%;
  padding: var(--gap);
  text-align: left;

  h3 {
    color: ${styles.colors.secondaryColor};
    font: ${styles.fontDecor({ size: `2.5rem` })};
    margin: 0 auto;
    max-width: 80%;
  }

  p {
    letter-spacing: 1px;
    margin: 0 auto;
    max-width: 90%;
    padding-top: var(--gap);
  }
`

const Highlight = styled.span`
  color: ${styles.colors.secondaryColor};
`

export default DishShowcase
