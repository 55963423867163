import React from "react"
import styled from "styled-components"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HomeHeader, BannerCenter } from "../utils"
import { colors } from "../utils/styles"
import DishShowcase from "../components/HomePageComponents/DishShowcase"
import { graphql } from "gatsby"
import DailySpecials from "../components/dailySpecials"
import SpecialOccasionModal from "../components/specialOccasionModal"

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70dvh;
  top: 5dvh;

  @media (min-width: 992px) {
    height: 60vh;
    top: 10vh;
  }
`

const InfoWrapper = styled.article`
  color: ${colors.mainWhite};
  display: grid;
  text-transform: uppercase;
  text-align: center;

  header {
    font-weight: 900;

    &::after {
      content: ":";
    }
  }

  & > div {
    line-height: 1.1;
  }

  footer {
    font-weight: 600;
    margin-top: 0.75rem;
  }
`

const InfoBox = styled.div`
  span {
    font-weight: 600;

    &::after {
      color: ${colors.secondaryColor};
      content: "|";
      font-weight: 400;
      padding: 0 0.25rem;
    }
  }
`

const IndexPage = ({ data }) => {
  const smImg = getSrc(data.smImg)
  const img = getSrc(data.img)
  const lgImg = getSrc(data.lgImg)
  const ogImg = getSrc(data.ogImg)

  return (
    <Layout>
      <Seo
        img={ogImg}
        title="Home"
        keywords={[`Restaurant and bar in Kenilworth, Asheville, NC`]}
      />
      <HomeHeader
        smImg={smImg}
        img={img}
        lgImg={lgImg}
        bgPosSm="20% 50%"
        bgPos="25% 50%"
        bgPosLg="35% 50%"
      >
        <ContentWrapper>
          <BannerCenter
            title="Neighborhood Bar & Scratch Kitchen"
            titleProps={{
              style: {
                fontSize: `clamp(1.5rem, 15vw, 3.8rem)`,
              },
            }}
            subtitle={[
              `Southern-Inspired Fare,`,
              `Local Drafts, Craft Cocktails & Wine`,
            ]}
          ></BannerCenter>
          <InfoWrapper>
            <header>Hours</header>
            <div>
              <InfoBox>
                <span>Dinner </span> Wed-Sun 3pm to 9pm
              </InfoBox>
              <InfoBox>
                <span>Breakfast </span> Sat-Sun 8am to 12pm
              </InfoBox>
            </div>
            <footer>5 Caledonia Rd, Asheville, NC</footer>
          </InfoWrapper>
        </ContentWrapper>
      </HomeHeader>
      <DailySpecials />
      <DishShowcase />
      <SpecialOccasionModal />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    smImg: file(
      relativePath: { eq: "fall-2024/patio-afternoon-food-p-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 90
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 576
        )
      }
    }
    img: file(relativePath: { eq: "fall-2024/patio-afternoon-l-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 90
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 992
        )
      }
    }
    lgImg: file(relativePath: { eq: "fall-2024/patio-afternoon-l-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 40
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 2000
        )
      }
    }
    ogImg: contentfulAsset(title: { eq: "ABK OG Image" }) {
      gatsbyImageData(
        formats: [JPG]
        height: 630
        placeholder: BLURRED
        quality: 75
        width: 1200
      )
    }
  }
`
